/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.main-menu .navbar-header .navbar-brand .brand-logo img {
	margin-top: -10px;
}

.wrapper {
	height: 100%;
	overflow-y: overlay;
}

.kanban-application {
	.app-kanban-wrapper {
		.board-wrapper {
			.task {
				cursor: auto !important;
			}
		}
	}

	.design-group {
		.design-planning {
			padding: 0.5rem;
			background-color: #f8f8f8;
			border-radius: 0.357rem;
		}
	}
}


.dark-layout {
	.kanban-application {
		.design-group {
			.design-planning {
				background-color: #161d31;
			}
		}
	}
}

a.dropdown-item.disabled:not([href]):hover {
	color: #b9b9c3;
}

.app-fixed-search {
	padding: 0.4215rem 0.5rem;
	border-bottom: 1px solid rgb(235, 233, 241);
	background-color: rgb(255, 255, 255);
	border-top-right-radius: 0.357rem;
}

.app-fixed-search .input-group-text,
.app-fixed-search input {
	border: 0px;
	background-color: transparent;
}

.app-fixed-search .input-group-text {
	height: auto !important;
	font-size: inherit !important;
}

.app-fixed-search .input-group:focus-within {
	box-shadow: none;
}

.rounded-pill.square {
	border-radius: 4px !important;
}

.nav-pills .nav-link.active .badge.bg-primary {
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
	color: #5e5873 !important;
}

.ecommerce-application .list-view .ecommerce-card .card-body .item-wrapper {
	order: 3 !important;
	margin-top: auto;
}

.ecommerce-application .list-view .ecommerce-card .card-body .item-description {
	order: 2 !important;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading {
	width: auto !important;
}

.custom-options-checkable .custom-option-item.is-invalid {
	color: #ea5455 !important;
	background-color: rgba(234, 84, 85, 0.06) !important;
	border: 1px solid #ea5455 !important;

	.custom-option-item-title {
		color: #ea5455 !important;
	}
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell .column-action svg.danger {
	stroke: #ea5455 !important;
}

.nav-pills .nav-item.danger .nav-link:not(.active) {
	span:not(.badge) {
		color: #ea5455 !important;
	}

	span.badge {
		background-color: #ea5455 !important;
	}

	svg {
		stroke: #ea5455 !important;
	}
}

.nav-pills .nav-item.danger .nav-link.active {
	background-color: #ea5455 !important;
	border-color: #ea5455 !important;
	box-shadow: 0 4px 18px -4px rgba(234, 84, 85, 0.651);
}

// .effect-1,
// .effect-2,
// .effect-3 {
// 	border-left: 3px solid #0066b3 !important;
// }

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-marker {
	margin-right: 10px !important;
	margin-left: 0 !important;
}

.chat-application {
	.sidebar-content {
		.chat-user-list-wrapper {
			.avatar {
				width: 42px;
			}
		}
	}
}

.fc-direction-rtl .fc-daygrid-event .fc-event-time {
    direction: ltr !important;
}

.fc-daygrid-dot-event {
    align-items: start !important;
}

.fc-daygrid-event-dot {
    display: none !important;
}

.fc-daygrid-dot-event .fc-event-title {
    white-space: normal !important;
    text-align: left !important;
}

.app-calendar .fc-popover .fc-popover-header .fc-popover-title, 
.app-calendar .fc-popover .fc-popover-header .fc-popover-close {
    direction: ltr !important;
}

.kanban-application .app-kanban-wrapper .board-wrapper {
    max-width: 25% !important;
}